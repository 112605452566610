@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins:wght@100;200;300&display=swap");

.icons {
  font-size: 40px;
}
.fs_7 {
  font-size: 10px !important;
}
.poppinfnt {
  font-family: "Poppins", "Montserrat";
  font-size: 14px;
}
.row {
  display: flex;
  align-items: center;
}

.borderlef {
  display: flex;
  flex-direction: column;
  align-items: center;
}

span.border-line {
  border-left: 1px solid black;
  height: 70px;
  margin-left: 20px;
}
.border-line4 {
  margin-left: 0px !important;
}
.borderlef1 {
  display: flex;
  align-items: center;
  align-items: center;
}

.border-line2 {
  border-bottom: 1px solid black;
  width: 60px;
}

.subct {
  font-weight: bolder;
  color: #068fff;
}
.c1 {
  background-color: #fff306;
}
.c2 {
  background-color: #06ffde;
}
.c3 {
  background-color: #344197;
  color: white;
}
.c4 {
  background-color: #d86bcf;
  color: white;
}

.c5 {
  color: white;
  background-color: #1d7305;
}
.logos {
  border-radius: 100%;
  /* height: 25px;
  width: 25px; */
}

.bold {
  font-weight: bold;
}
li,
.dropdown-item,
a {
  list-style-type: none;
}
li,
.dropdown-item,
a {
  text-decoration: none !important;
  color: black !important;
}
.cl {
  color: #068fff;
  margin-right: 5px;
}
.gHNpyS {
  border: 1px solid #d8d9da !important;
}
* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

.sidenav {
  width: 250px;
  flex: 0 0 auto;
  transition: width 0.3s ease-in-out;
  height: 200vh;
  background-color: #f9f9f9;
  padding-top: 28px;
}
@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 auto;
    width: 23% !important
    ;
  }
}
.c_W {
  color: white !important;
}
.sidenavClosed {
  height: 160vh;
  composes: sidenav;
  transition: width 0.3s ease-in-out;
  width: 60px;
  background-color: #f9f9f9;
}

.sideitem {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.linkText {
  padding-left: 16px;
}

.linkTextClosed {
  composes: linkText;
  visibility: hidden;
}
.h_p:hover {
  background-color: green !important;
}
.sideitem:hover {
  background-color: #090a0be3;
  color: white !important;
}
.active {
  color: #068fff;
}
.active1:hover {
  background-color: rgb(181, 45, 45);
}

.menuBtn {
  align-self: center;
  align-self: flex-start;
  justify-self: flex-end;
  color: black;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-left: 20px;
}
body {
  margin: 0%;
  padding: 0%;
}

.App {
  display: flex;
}

main {
  width: 100%;
}
.poppinclr {
  background-color: #06ffde17;
}
.img {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  color: black;
  margin: auto;
}

.hide {
  display: none;
}

.containerPadding {
  padding: 1%;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 40%;
  border: none;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding: 2px 16px;
}
.table-wrapper1 {
  height: 70vh;
}
.table-wrapper2 {
  height: 75vh;
}
.table-wrapper {
  height: 50vh;
}
.table-wrapper,
.table-wrapper1,
.table-wrapper2,
.table-wrapper3 {
  width: 100%;
  overflow: auto;
  position: relative;
  margin: 0%;
  border: 1px solid grey;
  border-radius: 5px;
}
.table-wrapper3 {
  height: 40vh;
}
.table-container table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}

.table-container thead {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 100;
}

.table-container tbody {
  overflow-y: scroll;
  display: block;
  scroll-behavior: smooth;
  overflow: hidden;
}

.table-container tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.table-container td,
.table-container th {
  padding: 8px;
  border: 1px solid #ccc;
  overflow: hidden;
  overflow-wrap: break-word;
}

.table-container::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.table-wrapper::-webkit-scrollbar,
.table-wrapper1::-webkit-scrollbar,
.table-wrapper2::-webkit-scrollbar,
.table-wrapper3::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.clr {
  font-weight: bold;
  color: #068fff;
}
.clr2 {
  color: #068fff;
}
.upload {
  padding: 5px;
  text-align: center;
  border-style: dashed;
  color: grey;
  background-color: #dce0e4;
}

.track-line {
  border-left: 2px solid #068fff;
  height: 80px;
  margin: 0px 0px 0px 16px;
}

.track-line1 {
  height: 170px;
}

.clr1 {
  font-size: 30px;
  font-weight: bold;
  margin-right: 5px;
}

/* Fonts */
:root {
  --font-primary: "Poppins", sans-serif;
}

/* Colours */
:root {
  --cream1: #ffeaca;
}

/* Selectors */
body {
  background-color: var(--cream1);
}

.calendar {
  animation: swing ease-in-out 1s infinite alternate;
  transform-origin: center -20px;
  box-shadow: 0 14px 14px -10px rgba(0, 0, 0, 0.25);
}

.calendar:before {
  position: absolute;
  content: "";
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: 5px;
  background-color: black;
  border-radius: 50%;
}

.calendar:after {
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -20px;
  content: "";
  width: 40px;
  height: 40px;
  border: 1px solid black;
  transform: rotate(45deg);
  z-index: -1;
}

.calendar__month {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75px;
  background-color: crimson;
  color: white;
  font-size: 40px;
}

/* Keyframes */
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  /* 100% {
    transform: rotate(-3deg);
  } */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  background: #f6f9fc;
}

.dlink {
  text-align: center;
  text-decoration: none !important;
  color: #f30367 !important;
}

.data-table-extensions-filter {
  border: 1px solid lightgray;
  border-radius: 50px;
  padding: 3px 5px;
  width: 20%;
}
.banrrad {
  border-radius: 20px;
}
.first {
  padding-right: 10px !important;
}

.filter-text {
  border: none !important;
  width: 80%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span.sc-bZcisz.sc-ikRtCJ.kUGFrh.fNqTxb {
  display: none;
}
.invoice_bill th {
  border: 1px solid black;
}
.invoice_bill td {
  border: 1px solid black;
}

.tdpadding {
  padding: 3px;
  text-align: center;
}

.notification {
  background-color: #068fff;
  width: 50px;
  border-radius: 100%;
  font-size: 30px;
  color: white;
  text-align: center;
}
.inputRadius {
  border-radius: 100px;
}
.data-table-extensions > .data-table-extensions-filter {
  display: none;
}
.p_style {
  font-size: 14px;
}
table th,
.thstyle {
  font-size: 14px !important;
  text-align: center !important;
  overflow-wrap: break-word !important;
  margin: auto !important;
}
.c_zoom {
  transition: transform 0.8s;
}
.c_zoom:hover {
  transform: scale(1.05);
  background-color: rgb(167, 33, 33);
  color: white;
}
table td {
  font-size: 13px;
  text-align: center;
}
.b-text {
  font-weight: bold;
}
.cureor {
  cursor: pointer;
}

.td_S,
.th_s {
  border: 1px solid black;
}

.th_s {
  font-size: 15px;
}
.tr_C:hover {
  background-color: #27c6c647;
}
/* .tr_C:nth-child(even) {
  background-color: #ccc !important;
} */
.tr2 th {
  border: 1px solid black;
}

.mroe {
  background-color: white;
  transition: background-color 0.3s ease;
}
.fnt35 {
  font-size: 35px;
  /* color: black; */
}
.mroe:hover {
  background-color: #d8d9da;
}
.clrw {
  color: white;
}
.fntc {
  color: black;
}
.iconfnt {
  font-size: 56px !important;
  /* color: black; */
}

.Invoice {
  /* Example styles */
  padding: 20px;
  border: 1px solid #ccc;
}
.grrn {
  color: green;
  font-weight: bold;
}
.board-container {
  display: grid;
  grid-template-columns: repeat(2, 3fr);
  gap: 5rem;
  transition: 0ms;
  padding: 4rem;
}
.board-type {
  background-color: white;
  box-shadow: 4px 4px 4px;
  padding: 25px;
  transition: transform 0.9s ease-in;
  width: 70%;
  border-radius: 10px;
}
.board-type:hover {
  transform: scale(1.1);

  background-color: rgba(0, 0, 0, 0.429);
  color: white;
}
